import { Carousel } from "@mantine/carousel";
import {
  Box,
  Container,
  Divider,
  Flex,
  Group,
  Image,
  List,
  Stack,
  Text,
  Title,
  TypographyStylesProvider,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconBackhoe,
  IconBriefcase,
  IconCalendar,
  IconMan,
  IconMap,
  IconPin,
  IconTrafficCone,
} from "@tabler/icons-react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import Content, { HTMLContent } from "../components/Content";
import Layout from "../components/Layout";

const convertMothToGerman = (month) => {
  switch (month) {
    case "January":
      return "Januar";
    case "February":
      return "Februar";
    case "March":
      return "März";
    case "April":
      return "April";
    case "May":
      return "Mai";
    case "June":
      return "Juni";
    case "July":
      return "Juli";
    case "August":
      return "August";
    case "September":
      return "September";
    case "October":
      return "Oktober";
    case "November":
      return "November";
    case "December":
      return "Dezember";
    default:
      return month;
  }
};

const replaceMonthInDateStringWithGerman = (dateString) =>
  // check if dateString is a string
  dateString
    ? String(dateString)
        ?.split(" ")
        .map((word) => convertMothToGerman(word))
        .join(" ")
    : dateString;

// eslint-disable-next-line
export const ProjectTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
  date,
  endDate,
  partner,
  location,
  coordinates,
  gallery,
  employeesCount,
  services,
}) => {
  const coords = useMemo(() => {
    if (coordinates && JSON.parse(coordinates)?.coordinates) {
      const [lng, lat] = JSON.parse(coordinates).coordinates;
      return [lat, lng];
    }
    return undefined;
  }, [coordinates]);
  const PostContent = contentComponent || Content;
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const [loadedImages, setLoadedImages] = React.useState(0);
  const [imagesCarouselKey, setImagesCarouselKey] = React.useState(0);

  return (
    <Box mt={56}>
      {helmet || ""}
      {gallery && gallery.length > 0 && (
        <>
          <Carousel
            withIndicators
            key={`carousel_gallery${imagesCarouselKey}`}
            // height="20rem"
            slideSize={"auto"}
            slideGap="xs"
            loop
            align={mobile ? "center" : "start"}
          >
            {gallery.map((image, i) => (
              <Carousel.Slide key={`slide_gallery_${i}`}>
                <Image
                  src={String(image)}
                  onLoad={(e) => {
                    if (imagesCarouselKey === 0) {
                      const newLoadedImages = loadedImages + 1;
                      if (loadedImages < gallery.length) {
                        setLoadedImages(newLoadedImages);
                      }
                      if (newLoadedImages === gallery.length) {
                        setImagesCarouselKey(1);
                        setLoadedImages(0);
                      }
                    }
                  }}
                  width="auto"
                  height="30rem"
                  alt={String(title)}
                  key={`image_gallery_${i}`}
                />
              </Carousel.Slide>
            ))}
          </Carousel>

          <Box h={8} bg="#b28a4f" />
        </>
      )}
      <Container size={mobile ? "xs" : "sm"} p={mobile ? "sm" : "xl"}>
        <Stack>
          <Title order={1} ff="Oswald" style={{ fontSize: "1.7rem" }}>
            {title}
          </Title>

          <List mt={20} w="100%" mx={5}>
            <Flex
              direction={"row"}
              align="start"
              gap={10}
              justify={"space-between"}
            >
              <List.Item
                style={{ flex: 1 }}
                icon={<IconCalendar size={24} color="gray" />}
                color="gray"
              >
                <Text size="sm" color="dark">
                  Ausführungszeitraum
                </Text>
              </List.Item>
              <Text style={{ flex: 1 }} size="sm" color="gray">
                {String(replaceMonthInDateStringWithGerman(date))} -{" "}
                {String(
                  endDate && endDate !== date
                    ? replaceMonthInDateStringWithGerman(endDate)
                    : "fortlaufend"
                )}
              </Text>
            </Flex>
            <Divider mb="sm" />
            <Flex
              direction={"row"}
              align="start"
              gap={10}
              justify={"space-between"}
            >
              <List.Item
                style={{ flex: 1 }}
                icon={<IconBriefcase size={24} color="gray" />}
                color="gray"
              >
                <Text size="sm" color="dark">
                  Auftraggeber
                </Text>
              </List.Item>
              <Text
                style={{ wordBreak: "break-word", flex: 1 }}
                size="sm"
                color="gray"
              >
                {partner}
              </Text>
            </Flex>
            <Divider mb="sm" />
            <Flex
              direction={"row"}
              align="start"
              gap={10}
              justify={"space-between"}
            >
              <List.Item
                style={{ flex: 1 }}
                icon={<IconPin size={24} color="gray" />}
                color="gray"
              >
                <Text size="sm" color="dark">
                  Standort
                </Text>
              </List.Item>
              <Text style={{ flex: 1 }} size="sm" color="gray">
                {location}
              </Text>
            </Flex>
            <Divider mb="sm" />
            <Flex
              direction={"row"}
              align="start"
              gap={10}
              justify={"space-between"}
            >
              <List.Item
                style={{ flex: 1 }}
                icon={<IconMan size={24} color="gray" />}
                color="gray"
              >
                <Text size="sm" color="dark">
                  Mitarbeiter
                </Text>
              </List.Item>
              <Text style={{ flex: 1 }} size="sm" color="gray">
                {employeesCount}
              </Text>
            </Flex>
            <Divider mb="sm" />
            <List.Item
              icon={<IconBackhoe size={24} color="gray" />}
              color="gray"
            >
              <Text size="sm" color="dark">
                Leistungen
              </Text>
            </List.Item>
            <List pl={16}>
              {services.map((service, i) => (
                <List.Item
                  icon={<IconTrafficCone size={24} color="gray" />}
                  color="gray"
                  key={`service_${i}`}
                >
                  <Text size="sm" color="gray">
                    {String(service)}
                  </Text>
                </List.Item>
              ))}
            </List>
          </List>
          <Divider mb="xl" />
          {coords && (
            <Group>
              <IconMap />
              <Title order={2} ff="Oswald" style={{ fontSize: "1.3rem" }}>
                Standort auf der Karte
              </Title>
            </Group>
          )}

          {coords && (
            <MapContainer
              style={{ height: "30rem", width: "100%" }}
              center={coords}
              zoom={18}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
              />
              <Marker position={coords}></Marker>
            </MapContainer>
          )}

          {content && (
            <>
              <Divider mt="xl" mb="xl" />
              <TypographyStylesProvider>
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </TypographyStylesProvider>
            </>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

ProjectTemplate.propTypes = {
  content: PropTypes.node,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
  date: PropTypes.string,
  endDate: PropTypes.string,
  partner: PropTypes.string,
  location: PropTypes.string,
  coordinates: PropTypes.object,
  gallery: PropTypes.array,
  employeesCount: PropTypes.string,
  services: PropTypes.array,
};

const Project = ({ data }) => {
  const { markdownRemark: project } = data;
  return (
    <Layout>
      <ProjectTemplate
        content={project.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Projekt">
            <title>{`${project.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${(
                project.frontmatter.services?.map((x) => x.service) ?? []
              ).join(", ")}`}
            />
          </Helmet>
        }
        date={project.frontmatter.date}
        endDate={project.frontmatter.endDate}
        title={project.frontmatter.title}
        partner={project.frontmatter.partner}
        location={project.frontmatter.location}
        coordinates={project.frontmatter.coordinates}
        gallery={project.frontmatter.gallery?.map((x) => x.image) ?? []}
        employeesCount={project.frontmatter.employeesCount}
        services={project.frontmatter.services?.map((x) => x.service) ?? []}
      />
    </Layout>
  );
};

Project.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Project;

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM YYYY")
        endDate(formatString: "MMMM YYYY")
        title
        partner
        location
        coordinates
        gallery {
          image
        }
        employeesCount
        services {
          service
        }
      }
    }
  }
`;
